<template>
  <ShiftReportsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :columns="columns"
      :rows="rows"
      :pageIndex="page + 1"
      :pageSize="perPage"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    >
      <template v-slot:title>
        {{ $tc('schedule', 2) | capitalize }}
      </template>
    </PanelTableMolecule>
    <router-view />
  </ShiftReportsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { getnScheduleOccurences } from '@common/utils/src'
import { SearchIcon } from 'vue-feather-icons'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'
import ShiftReportTableCell from './ShiftReports/ShiftReportTableCell.vue'
import { FlashMessages } from '@common/singletons'
import { LoadingDots } from '@common/components'

import SCHEDULES_QUERY from '#/graphql/operations/reports/schedulesWithLast7ShiftReportsQuery.graphql'
import CREATE_SHIFTREPORT_MANUALLY from '#/graphql/operations/reports/createShiftReportEventManually.graphql'

import { DateTime, Interval } from 'luxon'

const ShiftReportsStyled = styled('div')`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  .misc {
    display: flex;
    justify-content: flex-end;
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    ShiftReportsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      perPage: 10,
      page: 0,
      shiftReportCreatingFor: '',
    }
  },
  computed: {
    where() {
      const where = {
        createShiftReport: {
          equals: true,
        },
      }
      if (this.searchName) {
        where.name = {
          contains: this.searchName,
          mode: 'insensitive',
        }
      }
      if (this.searchCalendarName) {
        where.calendar = {
          is: {
            name: {
              contains: this.searchCalendarName,
              mode: 'insensitive',
            },
          },
        }
      }
      return where
    },
    searchName() {
      return this.$route?.query?.name
    },
    searchCalendarName() {
      return this.$route?.query?.calendar
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    skip() {
      return this.page * this.perPage
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'n',
          title: `Name`,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, __typename, name } = row
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchName}
                  placeholder="Search name"
                  on-valueChange={e => {
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        name: e,
                      },
                    })
                    this.page = 0
                  }}
                  on-cancel={() => this.searchNameCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        {
          field: 'calendar.name',
          key: 'c',
          title: `Calendar`,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, __typename, name } = row.calendar || {}
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchCalendarName}
                  placeholder="Search name"
                  on-valueChange={e => {
                    this.$router.push({
                      query: {
                        ...this.$route.query,
                        calendar: e,
                      },
                    })
                    this.page = 0
                  }}
                  on-cancel={() => this.searchCalendarCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        {
          field: 'calendar.owner',
          key: 't',
          title: 'Owner',
          renderBodyCell: ({ row }) => {
            const { id, type, label } = row?.owner
            if (!id || !type || !label) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={label} />
          },
        },
        {
          field: 'lastreport1',
          key: 'lr1',
          title: 'Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport1?.shiftReport || null
            const occurrence = row.lastreport1?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport2',
          key: 'lr2',
          title: '2nd Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport2?.shiftReport || null
            const occurrence = row.lastreport2?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport3',
          key: 'lr3',
          title: '3rd Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport3?.shiftReport || null
            const occurrence = row.lastreport3?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport4',
          key: 'lr4',
          title: '4th Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport4?.shiftReport || null
            const occurrence = row.lastreport4?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport5',
          key: 'lr5',
          title: '5th Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport5?.shiftReport || null
            const occurrence = row.lastreport5?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport6',
          key: 'lr6',
          title: '6th Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport6?.shiftReport || null
            const occurrence = row.lastreport6?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
        {
          field: 'lastreport7',
          key: 'lr7',
          title: '7th Last ShiftReport',
          align: 'center',
          renderBodyCell: ({ row, column, rowIndex }) => {
            const shiftReport = row.lastreport7?.shiftReport || null
            const occurrence = row.lastreport7?.occurrence || null
            const { isOtherReportLoading, isSelfReportLoading } = this.isReportGenerating(`${rowIndex}-${column.key}`)
            return isSelfReportLoading ? (
              <LoadingDots />
            ) : (
              <ShiftReportTableCell
                row={row}
                column={column}
                rowIndex={rowIndex}
                shiftReport={shiftReport}
                occurrence={occurrence}
                isButtonDisabled={isOtherReportLoading}
                timezone={row.timezone}
                on-triggerManualCreation={() => this.confirmCreateShiftReport(rowIndex, column.key, occurrence)}
              />
            )
          },
        },
      ]
    },
    rows() {
      return this.models.map(schedule => {
        const lastShiftInterval = schedule.previousShift?.interval || null
        const lastShiftEnd = lastShiftInterval ? Interval.fromISO(lastShiftInterval).end : null
        const last7Ocurrances = getnScheduleOccurences(schedule, 7, true, null, lastShiftEnd)
        const lastshiftReports = last7Ocurrances.reduce((acc, occurrence, i) => {
          const shiftReport = this.getShiftReportForOccurance(occurrence, schedule)
          acc[`lastreport${i + 1}`] = { shiftReport, occurrence }
          return acc
        }, {})
        return {
          ...schedule,
          ...lastshiftReports,
          owner: {
            type: schedule.calendar?.owner?.__typename,
            id: schedule.calendar?.owner?.id,
            label: schedule.calendar?.owner?.name,
          },
        }
      })
    },
  },
  methods: {
    getShiftReportForOccurance(occurrence, schedule) {
      const shiftReports = schedule.shiftReports

      const shiftReport = shiftReports.find(s => {
        return occurrence.date.toUTC().equals(DateTime.fromISO(s.shiftStart).toUTC())
      })
      return shiftReport
    },
    reload() {
      this.where = {
        createShiftReport: {
          equals: true,
        },
      }
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchNameCancel(closeFn) {
      this.$router.push({
        query: {
          ...this.$route.query,
          name: '',
        },
      })
      this.page = 0
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    searchCalendarCancel(closeFn) {
      this.searchCalendarName = ''
      this.$router.push({
        query: {
          ...this.$route.query,
          calendar: '',
        },
      })
      this.page = 0
      closeFn()
    },
    async createShiftReportManually({ rowIndex, colKey, occurrence }) {
      const schedule = this.rows[rowIndex]
      if (!schedule || !occurrence) {
        return
      }
      const scheduleInterval = Interval.fromDateTimes(occurrence.date, occurrence.date.plus(occurrence.duration)).toISO()
      this.shiftReportCreatingFor = `${rowIndex}-${colKey}`
      try {
        await this.$apollo.mutate({
          mutation: CREATE_SHIFTREPORT_MANUALLY,
          variables: {
            data: {
              recipientId: schedule?.calendar?.owner?.id,
              recipientType: schedule?.calendar?.owner?.__typename,
              eventTime: occurrence.date.plus(occurrence.duration).toISO(),
              scheduleId: schedule?.id,
              scheduleName: schedule?.name,
              scheduleInterval,
            },
          },
          update: async (store, { data }) => {
            const event = data.createShiftReportEventManually
            const storeData = store.readQuery({
              query: SCHEDULES_QUERY,
              variables: {
                take: this.perPage,
                skip: this.skip,
                where: this.where ? this.where : undefined,
              },
            })
            const schedules = storeData.schedules
            const aggregateSchedule = storeData?.aggregateSchedule
            const scheduleToUpdate = schedules.find(s => s.id === schedule?.id)
            scheduleToUpdate.shiftReports.push({
              id: event.id,
              createdAt: event.createdAt,
              shiftStart: event.shiftStart,
              actualCreatedAt: event.actualCreatedAt,
              __typename: event.__typename,
            })
            store.writeQuery({
              query: SCHEDULES_QUERY,
              variables: {
                take: this.perPage,
                skip: this.skip,
                where: this.where ? this.where : undefined,
              },
              data: {
                schedules,
                aggregateSchedule,
              },
            })
          },
        })
        this.shiftReportCreatingFor = ''
        this.$root.$emit('closeOverlay')
      } catch (err) {
        this.shiftReportCreatingFor = ''
        FlashMessages.$emit('error', err)
      }
    },
    isReportGenerating(key) {
      const isSelfReportLoading = key === this.shiftReportCreatingFor
      const isOtherReportLoading = this.shiftReportCreatingFor !== ''
      return {
        isOtherReportLoading,
        isSelfReportLoading,
      }
    },
    confirmCreateShiftReport(rowIndex, colKey, occurrence) {
      const schedule = this.rows[rowIndex]
      this.$root.$emit(
        'activateOverlay',
        'ConfirmOverlay',
        {
          confirmText: `
            Are you sure you want to create Shift Report for ${schedule?.calendar?.owner?.name}:
            <p>Schedule name: ${schedule?.name}</p>
            <p>
              Schedule interval: ${occurrence?.date?.toLocaleString(DateTime.DATETIME_FULL)} - ${occurrence?.end?.toLocaleString(
            DateTime.DATETIME_FULL,
          )}
            </p>
          `,
          onConfirm: this.createShiftReportManually,
          onConfirmArgs: {
            rowIndex,
            colKey,
            occurrence,
          },
        },
        this.onCloseSettings,
      )
    },
  },
  apollo: {
    models: {
      query: SCHEDULES_QUERY,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where ? this.where : undefined,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = data?.schedules
        this.totalCount = data?.aggregateSchedule?._count?._all || 0
      },
    },
  },
}
</script>
