<template>
  <TableCellStyled>
    <CreatedStyled v-if="shiftReport" :isDelayedReport="isDelayedReport">
      Created for:<br />
      {{ createdAt | formatDateTime }} {{ timezone }}
      <div class="delayed" v-if="isDelayedReport">
        Delayed by:<br />
        {{ delayedBy }}
      </div>
    </CreatedStyled>
    <div class="not-created" v-else>
      Planned time was<br />
      {{ plannedTime | formatDateTime }} {{ timezone }}
      <button @click="$emit('triggerManualCreation')" :disabled="isButtonDisabled">Create Manually</button>
    </div>
  </TableCellStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter, buttonReset } from '@styles/mixins'
import { DateTime } from 'luxon'

const TableCellStyled = styled('div')`
  ${flexCenter}
  justify-content: space-around;
  height: 100%;
  .not-created {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: ${p => chroma(p.theme.colors.red).css()};
    border-radius: 5px;
    height: 100%;
    padding: 2px;
    button {
      ${buttonReset}
      border-radius: 5px;
      background: ${p => p.theme.colors.white};
      padding: 5px;
      margin: 2.5px;
      margin-bottom: 5px;
      color: ${p => p.theme.colors.red};
      &:hover {
        color: ${p => chroma(p.theme.colors.red).alpha(0.5).css()};
      }
    }
  }
`
const CreatedStyled = styled('div')`
  padding: 5px 0px;
  background: ${p => (p.isDelayedReport ? chroma(p.theme.colors.darkerGrey).css() : 'none')};
  border-radius: 5px;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  .delayed {
    border-top: ${p => p.theme.colors.steelGrey} 1px solid;
    margin-top: 2px;
    padding-top: 2px;
    width: 100%;
  }
`

export default {
  components: {
    TableCellStyled,
    CreatedStyled,
  },
  props: {
    shiftReport: {
      type: Object,
      required: false,
    },
    occurrence: {
      type: Object,
      required: false,
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: 'local',
    },
  },
  computed: {
    plannedTime() {
      return this.occurrence.date.plus(this.occurrence.duration).setZone(this.timezone)
    },
    delayDuration() {
      return DateTime.fromISO(this.shiftReport.actualCreatedAt).diff(DateTime.fromISO(this.shiftReport.createdAt))
    },
    isDelayedReport() {
      return this.delayDuration.as('minutes') > 3
    },
    delayedBy() {
      return this.delayDuration.shiftTo('hours', 'minutes', 'seconds').toFormat("hh'h' : mm'm' : ss's'")
    },
    createdAt() {
      return DateTime.fromISO(this.shiftReport.createdAt).setZone(this.timezone)
    },
  },
}
</script>
