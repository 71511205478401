var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableCellStyled', [_vm.shiftReport ? _c('CreatedStyled', {
    attrs: {
      "isDelayedReport": _vm.isDelayedReport
    }
  }, [_vm._v(" Created for:"), _c('br'), _vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.createdAt)) + " " + _vm._s(_vm.timezone) + " "), _vm.isDelayedReport ? _c('div', {
    staticClass: "delayed"
  }, [_vm._v(" Delayed by:"), _c('br'), _vm._v(" " + _vm._s(_vm.delayedBy) + " ")]) : _vm._e()]) : _c('div', {
    staticClass: "not-created"
  }, [_vm._v(" Planned time was"), _c('br'), _vm._v(" " + _vm._s(_vm._f("formatDateTime")(_vm.plannedTime)) + " " + _vm._s(_vm.timezone) + " "), _c('button', {
    attrs: {
      "disabled": _vm.isButtonDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('triggerManualCreation');
      }
    }
  }, [_vm._v("Create Manually")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }